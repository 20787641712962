import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Profile from './components/Profile';
import FilterConfig from './components/FilterConfig';
import Filter from './components/Filter';
import { GlobalStyle } from './GlobalStyle';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/Loading';
import RecruiterProfile from './components/RecruiterProfile';
import Companies from './components/Companies';
import CompanyProfile from './components/CompanyProfile';
import Job from './components/Job';
import JobSelection from './components/JobSelection';
import SearchResults from './components/SearchResults';
import RustHome from './components/RustCommunity/RustHome';
import RecruiterLandingPage from './components/RecruiterLandingPage';
import JobRecs from './components/JobRecs';
import { 
  RustReportNov22,
  RustReportDec22,
  RustReportJan23,
  RustReportFeb23,
  RustReportMar23,
  RustReportApr23,
  RustReportMay23,
  RustReportJun23,
  RustReportJul23,
  RustReportAug23,
  RustReportSep23,
  RustReportOct23,
  RustReportNov23,
  RustReportDec23,
  RustReportJan24,
  RustReportFeb24,
  RustReportMar24,
  RustReportApr24,
  RustReportMay24,
  RustReportJun24,
  RustReportJul24,
  RustReportAug24
} from './components/RustCommunity/RustReport';
import {
  RustWingbackJul23,
  RustShuttleJul23,
  RustSvixAug23,
  RustGAMASep23,
  RustCorrodeOct23,
  RustOchagaviaOct23,
  RustKittyCADNov23,
  RustMeshCommDec23,
  RustAMPFeb24,
  RustFloxMar24,
  RustAmoMay24,
  RustMainmatterJun24,
  RustMeilisearchJul24,
  RustQuadraticAug24
} from './components/RustCommunity/DeepDives';
import RecruiterContacted from './components/RecruiterContacted';

function App() {

  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <GlobalStyle />
      <Routes>
        {/* Core */}
        <Route path='/' element={<LandingPage />} exact />
        <Route path='/profile' element={<Profile />} exact />
        <Route path='/filterconfig' element={<FilterConfig />} />
        <Route path='/filters/*' element={<Filter />} />
        <Route path='/recruiters/profile' element={<RecruiterProfile />} exact />
        <Route path='/recruiters/companies' element={<Companies />} exact />
        <Route path='/recruiters/companies/*' element={<CompanyProfile />} exact />
        <Route path='/recruiters/jobs/:companyId/:jobId' element={<Job />} />
        <Route path='/recruiters/job-selection' element={<JobSelection />} exact />
        <Route path='/recruiters/search' element={<SearchResults />} exact />
        <Route path='/recruiters/contacted' element={<RecruiterContacted />} exact />
        <Route path='/recruiter-info' element={<RecruiterLandingPage />} exact />
        <Route path='/job-recs' element={<JobRecs />} exact />
        {/* End Core */}

        {/* Rust Community */}
        <Route path='/rust' element={<RustHome />} exact />
        {/* Rust Report */}
        <Route path='/rust-nov-22' element={<RustReportNov22 />} exact />
        <Route path='/rust-dec-22' element={<RustReportDec22 />} exact />
        <Route path='/rust-jan-23' element={<RustReportJan23 />} exact />
        <Route path='/rust-feb-23' element={<RustReportFeb23 />} exact />
        <Route path='/rust-mar-23' element={<RustReportMar23 />} exact />
        <Route path='/rust-apr-23' element={<RustReportApr23 />} exact />
        <Route path='/rust-may-23' element={<RustReportMay23 />} exact />
        <Route path='/rust-jun-23' element={<RustReportJun23 />} exact />
        <Route path='/rust-jul-23' element={<RustReportJul23 />} exact />
        <Route path='/rust-aug-23' element={<RustReportAug23 />} exact />
        <Route path='/rust-sep-23' element={<RustReportSep23 />} exact />
        <Route path='/rust-oct-23' element={<RustReportOct23 />} exact />
        <Route path='/rust-nov-23' element={<RustReportNov23 />} exact />
        <Route path='/rust-dec-23' element={<RustReportDec23 />} exact />
        <Route path='/rust-jan-24' element={<RustReportJan24 />} exact />
        <Route path='/rust-feb-24' element={<RustReportFeb24 />} exact />
        <Route path='/rust-mar-24' element={<RustReportMar24 />} exact />
        <Route path='/rust-apr-24' element={<RustReportApr24 />} exact />
        <Route path='/rust-may-24' element={<RustReportMay24 />} exact />
        <Route path='/rust-jun-24' element={<RustReportJun24 />} exact />
        <Route path='/rust-jul-24' element={<RustReportJul24 />} exact />
        <Route path='/rust-aug-24' element={<RustReportAug24 />} exact />
        {/* Deep Dives */}
        <Route path='/rust-wingback-jul-23' element={<RustWingbackJul23 />} exact />
        <Route path='/rust-shuttle-jul-23' element={<RustShuttleJul23 />} exact />
        <Route path='/rust-svix-aug-23' element={<RustSvixAug23 />} exact />
        <Route path='/rust-gama-sep-23' element={<RustGAMASep23 />} exact />
        <Route path='/rust-corrode-oct-23' element={<RustCorrodeOct23 />} exact />
        <Route path='/rust-ochagavia-oct-23' element={<RustOchagaviaOct23 />} exact />
        <Route path='/rust-kittycad-nov-23' element={<RustKittyCADNov23 />} exact />
        <Route path='/rust-meshcomm-dec-23' element={<RustMeshCommDec23 />} exact />
        <Route path='/rust-amp-feb-24' element={<RustAMPFeb24 />} exact />
        <Route path='/rust-flox-mar-24' element={<RustFloxMar24 />} exact />
        <Route path='/rust-amo-may-24' element={<RustAmoMay24 />} exact />
        <Route path='/rust-mainmatter-jun-24' element={<RustMainmatterJun24 />} exact />
        <Route path='/rust-meilisearch-jul-24' element={<RustMeilisearchJul24 />} exact />
        <Route path='/rust-quadratic-aug-24' element={<RustQuadraticAug24 />} exact />
        {/* End Rust Community*/}
      </Routes>
    </>
  );
}

export default App;
