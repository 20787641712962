import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportAug24 = () => {
    const NUM_JOBS = 801
    const NUM_COMPANIES = 113
    const LARGE_HIRERS = [
        ['Amazon', 184],
        ['Microsoft', 64],
        ['IBM', 45],
        ['DataDog', 40],
        ['Apple', 36],
        ['Cloudflare', 33],
        ['Canonical', 26],
        ['SpaceX', 16],
        ['xAI', 15],
        ['Worldcoin', 14],
    ]
    const OTHER_HIRERS = [
        ['Tesla', 14],
        ['KeyRock', 12],
        ['Google ', 12],
        ['Discord', 11],
        ['GitHub', 10],
        ['Crypto.com', 9],
        ['Figma', 8],
        ['IOHK', 7],
        ['Okta', 7],
        ['Deliveroo', 6],
        ['Syndica', 6],
        ['HelsingAI', 6],
        ['Matic', 6],
        ['Fortanix', 6],
        ['Mozilla', 6],
        ['Ardan Labs', 5],
        ['informal', 5],
        ['Parity', 5],
        ['Smarkets', 5],
        ['Volume Finance', 5],
        ['Conduit', 5],
        ['Embark', 5],
        ['Deep Fence', 5],
        ['Akamai', 5],
        ['Hugging Face', 4],
        ['Heliax', 4],
        ['Sentry', 4],
        ['Stockly', 4],
        ['Dropbox', 4],
        ['Atlassian', 4],
        ['Ellipsis Labs', 4],
        ['Zelis', 4],
        ['ApolloGraphQL', 4],
        ['Threema', 4],
        ['Open Cosmos', 3],
        ['Svix', 3],
        ['Axiom', 3],
        ['Chorus One', 3],
        ['InfinyOn', 3],
        ['Aztec', 3],
        ['HealPay', 3],
        ['Prisma', 3],
        ['HyperExponential', 3],
        ['Vivint', 3],
        ['OneSignal', 3],
        ['Scaleway', 3],
        ['Imperva', 3],
        ['Mercedes', 3],
        ['Swift Navigation', 3],
        ['1Password', 3],
        ['amo', 2],
        ['OpenAI', 2],
        ['Zoo', 2],
        ['OpenTalk', 2],
        ['Railway', 2],
        ['TrueLayer', 2],
        ['StarkWare', 2],
        ['LINE', 2],
        ['PingCAP', 2],
        ['Zama', 2],
        ['OVH', 2],
        ['Osmosis Labs', 2],
        ['Fullstory', 2],
        ['Qovery', 2],
        ['10X Genomics', 2],
        ['Matter Labs', 2],
        ['Volka', 1],
        ['Tonari', 1],
        ['Zed Industries', 1],
        ['ZORA', 1],
        ['Two Sigma', 1],
        ['Shadow', 1],
        ['Uniswap Labs', 1],
        ['Argent', 1],
        ['Signal', 1],
        ['AppSignal', 1],
        ['FP Complete', 1],
        ['shuttle', 1],
        ['Qwiet AI', 1],
        ['Star Lab', 1],
        ['Ledger', 1],
        ['Grafbase', 1],
        ['PayPal', 1],
        ['Toyota Connected', 1],
        ['Astropad', 1],
        ['Ditto', 1],
        ['Clever Cloud', 1],
        ['Rapid7', 1],
        ['Meilisearch', 1],
        ['KION', 1],
        ['Cymo', 1],
        ['Liquid Analytics', 1],
        ['Qdrant', 1],
        ['UXStream', 1],
        ['Immunant', 1],
        ['Matchday', 1],
        ['DSCVR', 1],
        ['Qumulo', 1],
        ['IOTA Foundation', 1],
        ['Meta', 1],
        ['Scythe Robotics', 1],
        ['InfluxDB', 1],
        ['AllSpice', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 238],
        ['crypto', 94],
        ['productivity', 89],
        ['systems/hardware', 64],
        ['consultancy', 52],
        ['monitoring/reliability', 45],
        ['security', 38],
        ['data science', 37],
        ['iot/robotics/automotive', 29],
        ['messaging/notifications', 23],
    ]
    const OTHER_INDUSTRIES = [
        ['aerospace', 19],
        ['browser', 18],
        ['fintech', 14],
        ['databases', 10],
        ['marketplace', 10],
        ['gaming', 7],
        ['health/biotech', 6],
        ['social media', 3],
        ['networking', 3],
        ['animation', 2],
    ]
    const LEVELS = [
        ['junior', 13],
        ['mid', 458],
        ['senior', 330],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: August 2024'}
        subtitle={'Welcome to the August 2024 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'Oddly, the number of job postings stayed perfectly flat at 801 from July to August.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'While the number of job openings held steady, the hirer base continued to diversify, with the number of hirers growing by two.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The list of top hirers was shaken up slightly in August. The number of openings at Microsoft nearly halved, while IBM took a big jump up the leaderboard. Also, the generative AI craze has finally come for our leaderboard, with xAI posting 15 jobs.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"Our index of Rust companies is always growing, so our list of other hirers is always growing as well. A couple of interesting new companies we found in August were Mercedes and quantitative hedge fund, Two Sigma. If you know of a company that should be on this list but isn't, let us know: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The data science category is quickly moving up the list of top industries thanks to the AI-era. Should we make generative AI it\'s own category?'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={''}
        seniority={LEVELS}
        seniorityDesc={"All we can say at this point is, somebody please hire some entry-level Rust devs. There's lots of eager talent waiting."}
    />
}

export default RustReportAug24